<template>
  <dash-page-new
      :subtitle="$t('Settings')"
      :root="$t('Dashboard')"
      :root-location="businessDashLink('')"

      :title="$store.state.settings.pageData.title || $t('Settings')"
      :icon="$store.state.settings.pageData.icon || 'mdi-cogs'"
      :filters="$store.state.settings.pageData.filters || {}"
      :header-route="$store.state.settings.pageData.headerRoute || null"
      :filters-input="$store.state.settings.filtersData || []"
      @filter="$store.state.settings.filtersData = $event"

  >

    <template #header_action>
      <v-btn v-if="$store.state.settings.pageData.headerAction"
             @click="$store.state.settings.pageData.headerAction()"
             :color="wsATTENTION"
             class="noCaps"
             :block="SM"
             dark>
        {{ $store.state.settings.pageData.headerActionText }}
      </v-btn>
    </template>

    <template #default>
      <router-view></router-view>
    </template>



  </dash-page-new>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "BusinessSettings",
  data() {
    return {
      navigation : ''
    }
  },
  watch : {
    pageData : {
      handler(value) {
        if ( JSON.parse(JSON.stringify(value.navigationSelect)) !== JSON.parse(JSON.stringify(this.navigationSelect)) ) {
          this.$store.state.settings.navigationSelect = JSON.parse(JSON.stringify(value.navigationSelect))
        }
      },
      deep : true
    }
  },
  computed : {
    ...mapState('settings', ['pageData']),
  },
  mounted() {
    this.$store.state.settings.navigationSelect = this.navigationItems
  },
  beforeDestroy() {
    this.$store.state.settings.pageData = {}
    this.$store.state.settings.filtersData = {}
  }
}
</script>

<style scoped>

</style>